import styles from './VideoCard.module.css';
import { useTranslation } from 'react-i18next';
import { Button } from '../../atoms/Button';
import { Icons } from '../../assets/icons';
import VideoEmbedded from '../../atoms/VideoEmbedded';
import { useState } from 'react';

export type Provider = 'youtube' | 'loom';

export interface ThumbnailProps {
  imageUrl: string;
  backgroundImageUrl: string;
  playVideoTitle: string;
  playVideoTitleKey: string;
}

interface VideoCardProps {
  videoUrl: string;
  title: string;
  provider?: Provider;
  thumbnail: ThumbnailProps;
}

export const VideoCard = (props: VideoCardProps) => {
  const { videoUrl, title, provider = 'youtube', thumbnail } = props;
  const { t } = useTranslation();
  const [thumbnailVisibility, setThumbnailVisibility] = useState<boolean>(true);

  const onPlayButtonClick = () => {
    setThumbnailVisibility(false);
  };

  const playVideoTitle = thumbnail.playVideoTitleKey
    ? t(thumbnail.playVideoTitleKey, thumbnail.playVideoTitle)
    : thumbnail.playVideoTitleKey;

  return (
    <div className={`${styles.container} ${styles[provider]}`}>
      <VideoEmbedded videoUrl={videoUrl} title={title} provider={provider} />
      {thumbnail && thumbnailVisibility && (
        <div className={styles.thumbnailContainer}>
          {thumbnail.backgroundImageUrl && (
            <img
              className={styles.thumbnailBackgroundImage}
              src={thumbnail.backgroundImageUrl}
              alt=""
            />
          )}
          <img
            className={styles.thumbnail}
            src={thumbnail.imageUrl}
            alt={playVideoTitle}
          />
          <div className={styles.thumbnailOverlay}></div>
          <div className={styles.playButtonContainer}>
            <Button
              text={playVideoTitle}
              textKey=""
              icon={<Icons.ChevronRightRound />}
              onClick={onPlayButtonClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoCard;
