import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { LanguageCode } from '@mono/common';
import styles from './CourseModule.module.css';
import { ThumbnailProps, VideoCard } from '../../molecules/VideoCard';
import { Card, Text } from '../../atoms';
import { QuizComponent } from '../QuizComponent';
import { Skeleton } from '../../molecules';
import {
  convertLanguageCodeToEnum,
  getVideoProvider,
} from './CourseModule.helper';
import { Language, UserModuleResult } from '../../utils/interfaces';
import { Icons } from '../../assets/icons';
import { Brand } from '../../assets/brand';
import { Images } from '../../assets/images';

interface CourseModuleProps {
  module: UserModuleResult;
  isLastModule: boolean;
  languageCode: LanguageCode;
  doSaveProgress: () => void;
  onContinue: () => void;
  onGoBack: () => void;
  shuffleQuestionsAlternatives: () => void;
}

export const CourseModule = ({
  module,
  isLastModule,
  languageCode,
  doSaveProgress,
  onContinue,
  onGoBack,
  shuffleQuestionsAlternatives,
}: CourseModuleProps) => {
  const language: Language = convertLanguageCodeToEnum(languageCode);
  const isVideoModule = !!module.video;
  const videoUrl: string | undefined = module.video?.[language];
  const thumbnail: ThumbnailProps = {
    imageUrl: Brand.CosafeBook,
    backgroundImageUrl: Images.BlueBackground,
    playVideoTitle: 'Play video',
    playVideoTitleKey: 'module_button_playVideo',
  };

  const onGoBackClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onGoBack?.();
  };

  const getVideoContainer = () => {
    if (!videoUrl) {
      return (
        <Card>
          <Text
            textKey="module_video_notAvailable"
            text="Video is not available in the chosen language"
            fontSize="bodyLarge"
            variant="attention"
          />
        </Card>
      );
    }

    return (
      <div className={styles.videoContainer}>
        <VideoCard
          videoUrl={videoUrl}
          title={module.title}
          provider={getVideoProvider(videoUrl)}
          thumbnail={thumbnail}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Link to={'..'} onClick={onGoBackClick}>
          <Icons.LeftArrow />
        </Link>
        <Text
          testId="module-title"
          text={module.title}
          textKey={module.titleKey}
          fontSize="h2"
          fontWeight="bold"
        />
      </div>
      {isVideoModule && getVideoContainer()}
      <div className={styles.questionContainer}>
        <QuizComponent
          isReviewMode={module.completed}
          isLastModule={isLastModule}
          questions={module.questions}
          doSaveProgress={doSaveProgress}
          onContinue={onContinue}
          shuffleQuestionsAlternatives={shuffleQuestionsAlternatives}
        />
      </div>
    </div>
  );
};

export const CourseModuleLoader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Link to={'/'}>
          <Icons.LeftArrow />
        </Link>
        <Skeleton type="h2" />
      </div>
      <div className={`${styles.videoContainer} ${styles.loaderContainer}`}>
        <Skeleton type="card" />
      </div>
    </div>
  );
};
