import styles from './Avatar.module.css';
import { Icons } from '../../assets/icons';

export const Avatar = () => {
  return (
    <div className={styles.container}>
      <Icons.BustInSilhouette className={styles.icon} />
    </div>
  );
};
