import { useState } from 'react';
import { ChevronDown } from '../../assets/icons/ChevronDown';
import { Avatar, Text } from '../../atoms';
import styles from './UserCard.module.css';
import { ChevronUp } from '../../assets/icons/ChevronUp';
import { StaticMenu } from '../StaticMenu';
import { Icons } from '../../assets/icons';

interface UserCardProps {
  name: string;
  hasBorder: boolean;
  expansionDirection?: 'up' | 'down';
  isInitiallyExpanded?: boolean;
  doLogout: () => void;
}

const menuOptions = [
  {
    key: 'log_out',
    text: 'Log out',
    textKey: 'user_logout',
    icon: <Icons.Door />,
  },
];

export const UserCard = (props: UserCardProps) => {
  const {
    name,
    hasBorder,
    expansionDirection = 'down',
    isInitiallyExpanded,
    doLogout,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(
    isInitiallyExpanded || false,
  );

  const onChange = (key: string) => {
    if (key === 'log_out') {
      doLogout();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.container} ${isExpanded ? styles.expanded : ''} ${hasBorder ? styles.bordered : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Avatar />
        <div className={styles.text}>
          <Text
            text={name}
            fontSize="titleLarge"
            fontWeight="bold"
            truncate="oneLine"
          />
        </div>
        <div className={styles.expandIconContainer}>
          {isExpanded ? (
            <ChevronUp className={styles.expandIcon} />
          ) : (
            <ChevronDown className={styles.expandIcon} />
          )}
        </div>
      </div>
      {isExpanded && (
        <div className={`${styles.staticMenu} ${styles[expansionDirection]}`}>
          <StaticMenu menuOptions={menuOptions} onChange={onChange} />
        </div>
      )}
    </div>
  );
};
