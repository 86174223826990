import { Constants } from '@mono/common';
import { resetUser, setIsError, setIsLoginLoading, setUser } from '.';
import { AppDispatch, AppThunk, RootState } from '../../../store';
import { getProfile } from '../../../utils/api/core-api';
import {
  fetchSsoUrl,
  getAccountInfo,
  login as loginAPI,
  ssoLogin as ssoLoginAPI,
} from '../../../utils/api/legacy-api/auth-api/auth';
import { deleteItem, saveItem } from '../../../utils/storage';
import { getSsoRedirectUrl } from '../../../organisms/Login/Login.helper';

interface LoginProps {
  email: string;
  password: string;
}

interface SsoLoginProps {
  email: string | null;
  code: string;
  provider?: string;
}

export const extractErrorMessageFromResponse = (error: any) => {
  const errorCode = error?.response?.data?.code ?? error?.response?.data?.Code;
  if (!errorCode) return 'login_error';

  switch (errorCode) {
    case Constants.ResponseCode.INCORRECT_PASSWORD:
      return 'incorrect_password';
    case Constants.ResponseCode.TOO_MANY_ATTEMPTS:
      return 'login_error_many_attempts';
    default:
      return 'login_error';
  }
};

export const login = (props: LoginProps) => async (dispatch: AppDispatch) => {
  const { email, password } = props;
  try {
    dispatch(setIsLoginLoading(true));
    await loginAPI({
      email,
      password,
    });
    const user = await getProfile();
    saveItem('user', user);
    dispatch(setUser(user));
    dispatch(setIsLoginLoading(false));
  } catch (error) {
    const message = extractErrorMessageFromResponse(error);
    dispatch(setIsLoginLoading(false));
    dispatch(setIsError(`${message ? message : error}`));
  }
};

export const logoutUser = (): AppThunk => async (dispatch) => {
  deleteItem('user');
  deleteItem('csrf');
  dispatch(resetUser());
};

export const updateUser =
  (): AppThunk => async (dispatch, getState: () => RootState) => {
    dispatch(setIsLoginLoading(true));
    try {
      await getAccountInfo();
      const user = await getProfile();
      saveItem('user', user);
      dispatch(setUser(user));
      dispatch(setIsLoginLoading(false));
    } catch (error) {
      console.log('error log ', error);
      dispatch(
        getState().user?.user ? setIsError(`${error}`) : setIsError(null),
      );
      dispatch(logoutUser());
      dispatch(setIsLoginLoading(false));
    }
  };

export const redirectToSso =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      const redirectUrl = getSsoRedirectUrl(email);

      let url = 'AuthorizationUrl';
      url += '?email=' + encodeURIComponent(email);
      url += '&redirectUrl=' + encodeURIComponent(redirectUrl);

      const res = await fetchSsoUrl(url);
      window.location.href = res.url;
    } catch (error) {
      dispatch(setIsLoginLoading(false));
      dispatch(setIsError(`${error}`));
    }
  };

export const SsoLogin =
  (props: SsoLoginProps): AppThunk =>
  async (dispatch) => {
    const { email, code, provider } = props;
    try {
      dispatch(setIsLoginLoading(true));
      await ssoLoginAPI({
        email,
        code,
        url: getSsoRedirectUrl(email, provider),
        provider,
      });
      const user = await getProfile();
      saveItem('user', user);
      dispatch(setUser(user));
      dispatch(setIsLoginLoading(false));
    } catch (error) {
      console.log('error log ', error);
      dispatch(setIsLoginLoading(false));
      dispatch(setIsError(`${error}`));
    }
  };
