import { ReactElement } from 'react';
import styles from './StaticMenu.module.css';
import { Text } from '../../atoms';
import { textIncludesAnyOfTheChars } from '../../atoms/Text/Text.helper';

export interface MenuOption {
  key: string;
  text: string;
  textKey?: string;
  icon: ReactElement;
}

interface StaticMenuProps {
  menuOptions: MenuOption[];
  selected?: string;
  onChange: (key: string) => void;
}

export const StaticMenu = (props: StaticMenuProps) => {
  const { menuOptions, selected, onChange } = props;
  return menuOptions.map((option, index) => (
    <button
      key={option.key}
      className={`${styles.menuOption} ${selected === option.key ? styles.selected : ''} ${index === 0 ? styles.first : ''} ${index === menuOptions.length - 1 ? styles.last : ''}`}
      onClick={() => onChange(option.key)}
    >
      {option.icon}
      <span
        className={`${styles.text} ${textIncludesAnyOfTheChars(option.text, ['(', ')']) ? styles.textWithBrackets : ''}`}
      >
        <Text
          textKey={option.textKey}
          text={option.text}
          fontSize="titleLarge"
          fontWeight="regular"
          variant={selected === option.key ? 'attention' : 'none'}
        />
      </span>
    </button>
  ));
};
