import { GreatBritainFlag } from '../assets/icons/flags/GreatBritainFlag';
import { NetherlandsFlag } from '../assets/icons/flags/NetherlandsFlag';
import { SwedenFlag } from '../assets/icons/flags/SwedenFlag';

/** @param 'page' -- Meaning current page. So it equals index + 1. May be provided as 'string' */
export const pageToOffset = (limit: number, page: number | string) => {
  const pageIndex = Number(page) - 1;

  if (
    typeof limit !== 'number' ||
    typeof pageIndex !== 'number' ||
    isNaN(pageIndex) ||
    limit < 0 ||
    pageIndex < 0
  ) {
    return 0;
  }

  const offset = pageIndex * limit;
  return offset;
};

type Language = {
  name: string;
  icon: JSX.Element;
};

export const getLanguages = (className?: string): Record<string, Language> => {
  return {
    en: {
      name: 'English (UK)',
      icon: <GreatBritainFlag className={className} />,
    },
    se: { name: 'Svenska', icon: <SwedenFlag className={className} /> },
    nl: {
      name: 'Nederlands',
      icon: <NetherlandsFlag className={className} />,
    },
  };
};

export const getLanguageByCode = (code: string, className?: string) => {
  const languages = getLanguages(className);
  return languages[code];
};
